@import '../bootstrap/functions';

// Bootstrap overrides START HERE

$primary: #2d6f83;

// Bootstrap overrides ENDS HERE
@import '../bootstrap/variables';
@import '../bootstrap/mixins';

// Custom components overrides START HERE
$footer-bg-color: theme-color($key: 'light');
$footer-color: color($key: 'black');

.navbar-theme-colors {
  @extend .navbar-dark;
  @extend .bg-primary;
}

// Custom components overrides ENDS HERE
@import '../core';

:root {
  font-size: 14px;
}