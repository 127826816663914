body {
  background-color: #fff;
  min-height: 100vh;
}

.hidden {
  display: none;
}

.z-index-1 {
  z-index: 1;
}

.fit {
  width: 1%;
  white-space: nowrap;
}

.img-shadow {
  border-radius: 5px;
}

.cover {
  height: 40vh;
  overflow: hidden;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &:before {
    content: '';
    background-color: rgba(0, 0, 0, .7);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#course-module-container {
  height: calc(100vh - 56px);
  overflow: hidden;

  #course-module-content {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.background-cover-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  &::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;

    width: 100vw;
    height: 100vh;
  }
}

.sticky-top-padding {
  padding-top: 4.3rem;
}

.vertical-align-table td {
  vertical-align: middle;
}

@mixin positional {

  $positions: "left", "right", "top", "bottom";
  $brackpoints: "0", "25", "50", "75", "100";

  @each $position in $positions {
    @each $brackpoint in $brackpoints {
      .#{$position}-#{$brackpoint} {
        #{$position}: #{$brackpoint}#{'%'}
      }
    }
  }
}

@include positional();

.full-price {
  text-decoration: line-through;
}
.discount-price{
  color: $danger;
}
.text-thin{
  font-weight: 200;
}

.arrow-bottom{
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  height: 10px;
  width: 10px;

  transform: rotate(45deg);
}

.raw-text {
  white-space: pre-wrap;
}

.input-group-prefix {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}