.carousel {
  .carousel-float-caption {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .caption{
    width: 95%;
    text-align: center;

    color: #F7F7F7;
  }

  .carousel-item::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    //background-color: rgba(0,0,0,0.6);
  }
}
